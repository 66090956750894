// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import RailsUJS from "@rails/ujs";
RailsUJS.start();
require("@rails/activestorage").start();
require("channels");

import "./plugins/hotwired";

// Emit events trough window object
import EventEmitter from "events";
window.emitter = new EventEmitter();
import * as bootstrap from "bootstrap";
import { createPopper } from "@popperjs/core";
import jquery from "jquery";
// Makes it usable in views
window.$ = jquery;
// Required for bootstrap v5. Some places still use with jquery like in bootstrap v3/v4
window.jQuery = jquery;
window.bootstrapModal = bootstrap.Modal;
import axios from "axios";

require("@nathanvda/cocoon");

import confirmationDialog from "./mixins/confirmationDialog";
import { addLoading } from "./mixins/btnLoading";

//import "./themeMode.js";

import "./plugins/datepicker";
import "./plugins/dayjs";
import "./plugins/datepicker";
import "./plugins/select2";
import tinymce from "tinymce";

window.addClickedLoadingIndicator = function (el) {
  addLoading(el);
};

function setSessionMessage() {
  const notice = sessionStorage.getItem("notice");
  const alert = sessionStorage.getItem("alert");
  if (notice) {
    sessionStorage.removeItem("notice");
    $("#noticeAlert").text(notice);
    $("#noticeAlert").removeClass("d-none");
  }
  if (alert) {
    sessionStorage.removeItem("alert");
    $("#alertAlert").text(alert);
    $("#alertAlert").removeClass("d-none");
  }
}

function initExpandableRow() {
  $("tr[expandable-row]").each((k, el) => {
    const btn = $(el).find("[expand-btn]");
    if (btn.length == 1) {
      $(btn).on("click", evt => {
        evt.preventDefault();
        $(btn).toggleClass("rotate-up");
        const parent = $(btn).parents("tr");
        $(parent).toggleClass("table-active");
        $(parent).next().toggleClass("d-none");
      });
    }
  });
}

window.reInitExpandableRow = selector => {
  $(`${selector} tr[expandable-row]`).each((k, el) => {
    const btn = $(el).find("[expand-btn]");
    if (btn.length == 1) {
      $(btn).on("click", evt => {
        evt.preventDefault();
        $(btn).toggleClass("rotate-up");
        const parent = $(btn).parents("tr");
        $(parent).toggleClass("table-active");
        $(parent).next().toggleClass("d-none");
      });
    }
  });
};

document.addEventListener("turbo:visit", () => {
  document.body.classList.add("cursor-loading");
});

document.addEventListener("turbo:load", () => {
  document.body.classList.remove("cursor-loading");

  setUpModal();

  const token = document.getElementsByName("csrf-token");
  if (token && token[0]?.content) {
    axios.defaults.headers.common["X-CSRF-Token"] = token[0].content;
    axios.defaults.headers.common["Accept"] = "application/json;charset=UTF-8";
  } else {
    return; // no token, no point continuing.
  }

  // Element that when hidden from view makes "scroll up" button shown
  const scrollBtnTigger = document.querySelector("#scrollBtnObserver");
  scrollBtnTigger.classList.add("scroll-observer");

  const scrollbtn = document.querySelector(".scroll-up");
  new IntersectionObserver(function (entries) {
    if (entries[0].intersectionRatio <= 0) {
      // When "#scrollBtnObserver" is no longer "visible" in browser
      scrollbtn.classList.remove("d-none");
    } else {
      // When "#scrollBtnObserver" is "visible" in browser again
      scrollbtn.classList.add("d-none");
    }
  }).observe(scrollBtnTigger);

  scrollbtn.addEventListener("click", () => {
    window.scrollTo(0, 0);
  });

  initExpandableRow();
  setSessionMessage();
  window.requestAnimationFrame(() => {
    initDatepicker();
    initSelect2();
  });

  if ($(".nav.nav-tabs").length > 0) {
    $(".nav.nav-tabs").each((i, el) => {
      if (
        $(el).find(".nav-link.active").length > 0 ||
        (window.location.hash &&
          document.querySelector(
            /* format: xxx-'name here to open tab'*/
            `a.nav-link[href="#${window.location.hash.split("-")[1]}"]`
          ))
      ) {
        /**
         * If there is a nav-tabs with.active class, skip or if hash exists and tab nav found,
         * skip. (Should be handled in its respective controller, e.g people and marking_sheets controller)
         */
        return;
      }
      const navLink = $(el).find(".nav-link")[0];
      if (navLink) navLink.click();
    });
  }

  document.querySelectorAll(`[data-bs-toggle="tooltip"]`).forEach(el => {
    new bootstrap.Tooltip(el);
  });

  document.querySelectorAll(`.dropdown-toggle`).forEach(el => {
    new bootstrap.Dropdown(el, {
      boundary: "window"
    });
  });

  document.querySelectorAll(`[data-confirm]`).forEach(el => {
    el.addEventListener(
      "click",
      evt => {
        evt.preventDefault();
        evt.stopPropagation();
        confirmationDialog(evt);
      },
      { capture: true },
      true
    );
  });

  if (document.querySelector("#loginForm")) setUpLoginForm();
  require("./legacy");
});

document.addEventListener("turbo:before-frame-render", e => {
  e.preventDefault();
  // Needs to be removed or it wont init again
  tinymce.remove();
  e.detail.resume();
});

document.addEventListener("turbo:frame-load", () => {
  window.requestAnimationFrame(() => {
    initDatepicker();
    initSelect2();
  });
});

document.addEventListener("turbo:submit-end", e => {
  // Reinit tooltips
  removeVisibleTooltips();
  document.querySelectorAll(`[data-bs-toggle="tooltip"]`).forEach(el => {
    const tooltip = bootstrap.Tooltip.getInstance(el);
    if (!tooltip) new bootstrap.Tooltip(el);
  });

  // Do not scroll if form is inside modal, triggered by shadow form or form with button_to class
  // Shadow form as in rails ujs/turbo confirm is used on <a> and clicking it creates new one
  if (
    !(
      e.target.hidden || // When its link but has data-turbo-method="post" which creates seperate form action
      e.target.closest("#modal") || // When its inside modal or confirmation variant for turbo
      e.target.closest("#modalTurbo") ||
      e.detail.formSubmission.formElement.style.display ==
        "none" /* Shadow form is hidden*/ ||
      e.detail.formSubmission.formElement.classList.contains("button_to")
    )
  ) {
    document.querySelector("main.container").scrollIntoView();
    window.scroll(0, -50);
  }

  // This custom event needs to be manually attached trough stimulus and not trough data-action because
  // stimulus doesn't support custom events
  e.detail.formSubmission.formElement.dispatchEvent(
    new CustomEvent("form:submit-end", { detail: e.detail })
  );

  setTimeout(() => {
    window.requestAnimationFrame(() => {
      // Reinit datepicker and select2 again
      initDatepicker(); // If this fails then dates get screwed up/not even be send to server
      initSelect2();
    });
  }, 15); // Ensures turbo_stream has updated the DOM by that time
});

document.addEventListener("turbo:before-cache", () => {
  // Prevent caching tooltip elements
  removeVisibleTooltips();
  // Needs to be removed or it wont init again
  tinymce.remove();
});

function setUpLoginForm() {
  const loginBtn = document.querySelector("#loginFormButton");
  const loginForm = document.querySelector("#loginForm");

  const popper = createPopper(loginBtn, loginForm, {
    placement: "bottom-end"
  });

  let loginBox = false;
  const loginFormDialog = async () => {
    loginBox = !loginBox;

    if (!$("#loginFormOverlay")[0] && loginBox == true) {
      const overlay = document.createElement("div");
      overlay.id = "loginFormOverlay";
      overlay.classList.add("rts-overlay");
      $("body").append(overlay);
    }

    $("#loginForm").toggleClass("d-none");
    //$("body").toggleClass("overflow-hidden");
    await popper.update();

    if (loginBox) {
      $("#loginFormOverlay").on("click", () => {
        $("#loginForm").addClass("d-none");
        $("#loginFormOverlay").remove();
        //$("body").toggleClass("overflow-hidden");
        loginBox = false;
      });
    } else {
      loginBox = false;
      $("#loginFormOverlay").remove();
    }
  };

  $("#loginFormButton, #loginForm .close").on("click", loginFormDialog);
}

function removeVisibleTooltips() {
  document
    .querySelectorAll(`.tooltip[role="tooltip"]`)
    .forEach(el => el.remove());
}

function setUpModal() {
  const modal = document.querySelector("#modal");
  const modalInstance = bootstrap.Modal.getOrCreateInstance(modal);
  modal.addEventListener("hidden.bs.modal", () => {
    const modalContent = modal.querySelector("#modalContent");
    Array.from(modalContent.children).forEach(el => el.remove());
  });
  const domObserver = new MutationObserver(mutationList => {
    for (const mutation of mutationList) {
      for (const node of mutation.addedNodes) {
        if (node.name === "modalClose") {
          // If found input with that value in name attribute, then close modal automatically
          modalInstance.hide();
        } else if (node.name === "modalOpen") {
          // Or if its this value, then automatically open
          modalInstance.show();
        }
      }
    }
  });
  const modalContent = modal.querySelector("#modalContent");
  // Observe modal's content for input with name="closeModal"
  domObserver.observe(modalContent, { childList: true });
}
